<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0 text-danger">
          <span class="text-uppercase">SİLİNEN MÜŞTERİLER</span>
        </h5>
      </div>
      <div class="card-body">
        <WeTable
          v-bind:index="false"
          v-bind:data="member.list"
          v-bind:allowSortIndex="false"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
          v-bind:ajax="true"
          v-bind:loading="loading"
          v-bind:table-result="tableResult"
          v-on:make-request="updateMemberList"
          ref="memberTable"
        />
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Trash",
  data() {
    return {
      loading: false,
      filter: {
        deleted_at: 1,
      },
      tableActions: [
        {
          icon: "fas fa-sync fa-sm",
          class: "btn-outline-primary",
          action: "restore",
          tooltip: "Geri Al",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
          tooltip: "Kalıcı Olarak Sil",
        },
      ],
      columns: [
        { name: "full_name", th: "Müşteri Adı Soyadı", type: "string" },
        { name: "email", th: "E-Posta", type: "string" },
        { name: "current_code", th: "Cari Hesap Kodu", type: "string" },
        { name: "member_type", th: "Üye Tipi", type: "string" },
        { name: "member_group", th: "Üye Grubu", type: "string" },
        { name: "created_at", th: "Üyelik Tarihi", type: "datetime" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("member", ["getList", "restore", "remove"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onRemove(data.row);
      } else if (data.key == "restore") {
        this.onRestore(data.row);
      }
    },
    onRestore(row) {
      let message = `${row.full_name} adlı müşteriyi geri almak istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Geri Al",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("r");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.restore({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Müşteri Başarıyla Geri Alındı");
                  this.updateMemberList();
                } else {
                  this.$swal({
                    title: "Müşteri Geri Alınamadı",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onRemove(row) {
      let message = `${row.full_name} adlı müşteriyi silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        text: "Bu işlem geri alınamaz.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("f");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.remove({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Müşteri Başarıyla Silindi");
                  this.updateMemberList();
                } else {
                  this.$swal({
                    title: "Müşteri Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    updateMemberList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        filter: this.filter,
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("member", code);
    },
  },
  computed: {
    ...mapState({
      member: (state) => state.member,
    }),
  },
  mounted() {
    this.updateMemberList();
  },
};
</script>

<style lang="scss" scoped>
.img-popover {
  color: #2f3542;
}

.img-popover:hover {
  text-decoration: none;
  color: #535455;
}
</style>
